<template>
  <div class="container">
    <div class="row" style="margin-top: 50px;">
      <div class="col-md-12">
        <div class="card-custom">
          <form id="add-apartment">
          <div class="header-form"><h3>{{ ((id === null || id === undefined)? 'New Apartment:  ' : 'Apartment :   ')}} {{name}}</h3></div>
          <div class="modal-body">
            <div class="row text-center">
              <div class="col-md-8">
                <label class="control-label" >Name </label>
                <input type="text"  class="form-control" v-model="name">
              </div>
              <div class="col-md-4">
                <label class="control-label">Space</label>
                <input type="text" class="form-control" v-model="space">
              </div>
              <div class="col-md-6">
                <div class="form-group field-ticketform-daterange">
                  <label class="control-label" data-custom-for="ticketform-daterange">Time for Schedule</label>
                  <div>
                    <flat-pickr v-model="dateRange"
                                :config="config"
                                class="form-control"
                                placeholder="Select Date & Time"
                                style="background-color: white;"
                    >
                    </flat-pickr>
                  </div>
                  <p class="help-block help-block-error"></p>
                </div>
              </div>
              <div class="col-md-6">
                <label class="control-label" data-custom-for="ticketform-help_topic_id">Type of service layout</label>
                <Multiselect
                    v-model='topicId'
                    placeholder='Select type of service...'
                    label='name'
                    trackBy='name'
                    valueProp='id'
                    :searchable='true'
                    :options='topics'
                >
                </Multiselect>
      <!--          <p class="help-block help-block-error"></p>-->
              </div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <label class="control-label">Owner / Customer</label>
                  <Multiselect
                      v-model='ownerId'
                      placeholder='Select owner / customer or ADD NEW...'
                      :searchable='true'
                      label='name'
                      valueProp='id'
                      trackBy='name'
                      @select='addExternalCustomer'
                      :options='owners'
                  >
                  </Multiselect>
                </div>
                <div class="col-md-4 align-items-center" >
                  <label class="control-label" style="color: transparent; display: block" data-custom-for="ticketform-estimated_time">Save customer</label>
                  <button type="button" style="float: left" class="btn btn-danger" @click="addExternalCustomer" >&#10011; ADD NEW</button>
                </div>
                <p class="help-block help-block-error"></p>
              </div>
              <div class="col-md-12">
                <form id="add-customer" @submit.prevent="addCustomer">
                  <div class="customer-detail" :style="addExternalCustomerForm">
                    <h5>Customer detail:</h5>
                    <div class="row">
                      <div class="col-md-6">
                        <label class="control-label" data-custom-for="ticketform-estimated_time">Name / Company name</label>
                        <input type="text" data-custom-id="ticketform-estimated_time" class="form-control" v-model="customerName" required>
                        <p class="help-block help-block-error"></p>
                      </div>
                      <div class="col-md-6">
                        <label class="control-label" data-custom-for="ticketform-estimated_time">Contact person name</label>
                        <input type="text" data-custom-id="ticketform-estimated_time" class="form-control" v-model="contactName">
                        <p class="help-block help-block-error"></p>
                      </div>
                      <div class="col-md-4">
                        <label class="control-label" data-custom-for="ticketform-estimated_time">Email</label>
                        <input type="email" data-custom-id="ticketform-estimated_time" class="form-control" v-model="contactEmail">
                        <p class="help-block help-block-error"></p>
                      </div>
                      <div class="col-md-4">
                        <label class="control-label" data-custom-for="ticketform-estimated_time">Tel</label>
                        <input type="tel" data-custom-id="ticketform-estimated_time" class="form-control" v-model="contactTel">
                        <p class="help-block help-block-error"></p>
                      </div>
                      <div class="col-md-4">
                        <label class="control-label" data-custom-for="ticketform-estimated_time">OIB</label>
                        <input type="text" data-custom-id="ticketform-estimated_time" class="form-control" v-model="customerOIB" required>
                        <p class="help-block help-block-error"></p>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-md-8">
                          <label class="control-label" >Address</label>
                          <div class="input-group">
                            <a class="input-group-text" title="Search address on Map" @click="searchAddressOnMap">
                              <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
                            </a>
                            <input type="text" class="form-control" placeholder="Type address here... and click search" v-model="customerAddress" >
                          </div>
                        </div>
                        <div class="col-md-4 align-items-center" >
                          <label class="control-label" style="color: transparent; display: block" data-custom-for="ticketform-estimated_time">Save customer</label>
                          <input type="submit" style="float: left" class="btn btn-danger" value="&#10011; Save">
                          <button type="button" class="btn btn-danger" @click="addExternalCustomer" >&#10007; Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                </div>
              </div>
            <hr/>
            <div class="row text-center">
              <div class="col-md-6">
                <div class="form-inline">
                  <label class="control-label">Office</label>
                  <Multiselect style="background-color: white"
                               v-model='officeId'
                               placeholder='Select office...'
                               label='name'
                               trackBy="name"
                               valueProp="id"
                               :searchable='true'
                               :options='offices'
                  >
                  </Multiselect>
                  <!--              <p class="help-block help-block-error"></p>-->
                </div>
                <div class="form-inline">
                  <label class="control-label">City Name</label>
                  <input type="text" class="form-control" v-model="cityName">
                </div>
                <div class="form-inline">
                  <label class="control-label" >Address  </label>
                  <div class="input-group">
                    <a class="input-group-text" title="Search address on Map" @click="searchAddressOnMap">
                      <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
                    </a>
                    <input type="text" class="form-control" placeholder="Type address here... and click search" v-model="address" >
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-6">
                    <label class="control-label">Latitude</label>
                    <input type="text" class="form-control" v-model="latitude">
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">Longitude</label>
                    <input type="text" class="form-control" v-model="longitude">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div id="map"></div>
              </div>
            </div>
            <hr/>
            <div class="row">
              <div class="col-md-6">
                <label class="control-label">One linen change price (for all beds)</label>
                <input type="text" class="form-control" v-model="priceOneLinen">
              </div>
              <div class="col-md-6">
                <label class="control-label">One linen change cost</label>
                <input type="text" class="form-control" v-model="costOneLinen">
              </div>
              <p class="help-block help-block-error"></p>

                <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label col-md-3"></label>
                  <label class="control-label col-md-3">Price</label>
                  <label class="control-label col-md-3">Cost</label>
                  <label class="control-label col-md-3">Time</label>
                  <div class="input-group">
                    <div class="input-group-prepend col-md-3">
                      <span class="input-group-text form-control" >Service</span>
                    </div>
                    <input type="text" class="form-control" v-model="priceCleaning"  placeholder='Price'>
                    <input type="text" class="form-control" v-model="costCleaning"  placeholder='Cost'>
                    <input type="text" class="form-control" v-model="timeCleaning"  placeholder='Time'>
                  </div>
      <!--            <p class="help-block help-block-error"></p>-->
                </div>
                <div class="form-group">
                  <label class="control-label col-md-3"></label>
                  <label class="control-label col-md-3">Price</label>
                  <label class="control-label col-md-3">Cost</label>
                  <label class="control-label col-md-3">Time</label>
                  <div class="input-group">
                    <div class="input-group-prepend col-md-3">
                      <span class="input-group-text form-control" >General Cleaning</span>
                    </div>
                    <input type="text" class="form-control" v-model="priceGeneralCleaning"  placeholder='Price'>
                    <input type="text" class="form-control" v-model="costGeneralCleaning"  placeholder='Cost'>
                    <input type="text" class="form-control" v-model="timeGeneralCleaning"  placeholder='Time'>
                  </div>
      <!--            <p class="help-block help-block-error"></p>-->
                </div>
                <div class="form-group">

                  <label class="control-label col-md-3"></label>
                  <label class="control-label col-md-3">Price</label>
                  <label class="control-label col-md-3">Cost</label>
                  <label class="control-label col-md-3">Time</label>
                  <div class="input-group">
                    <div class="input-group-prepend col-md-3">
                      <span class="input-group-text form-control">Touch-Up Cleaning</span>
                    </div>
                    <input type="text" class="form-control col-md-3" v-model="priceTouchUpCleaning"  placeholder='Price'>
                    <input type="text" class="form-control col-md-3" v-model="costTouchUpCleaning"  placeholder='Cost'>
                    <input type="text" class="form-control col-md-3" v-model="timeTouchUpCleaning"  placeholder='Time'>
                  </div>
      <!--            <p class="help-block help-block-error"></p>-->
                </div>
                </div>
              </div>
          </div>
          <div class="footer-form" style="display: block">
            <div class="row">
              <div class="col-md-12">
                <!--                  <button type="button" class="btn btn-danger" @click="" >&#10007; Cancel</button>-->
                <input type="reset" value="&#x2704; Clear form" class="btn btn-warning" style="margin-right:10px">
                <input type="button" class="btn btn-primary" v-if="id === null || id === undefined" value="&#10011; Add new Apartment" @click="addNewObject">
                <input type="button" class="btn btn-primary" v-else value="&#10001; Update" @click="updateApartment">
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "@/store";
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import {ActionTypes} from "@/store/actions";
import axios from "axios";
import {MutationType} from "@/store/mutations";
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";
import ticketService from "@/services/tickets.service";
import ownerService from "@/services/ownerService";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import router from "@/router";


export default {
  name: "MapContent",
  setup(props) {
    const store = useStore();
    const topics = computed(() => store.getters.topics);
    // const offices = computed(() => store.getters.offices);
    const offices = [{"id":5,"name":"Kvarner"},{"id":8,"name":"Cervar"},{"id":10,"name":"Savudrija"},{"id":6,"name":"Gajac"},
    {"id":9,"name":"Mareda"},{"id":11,"name":"Zaglav"},{"id":7,"name":"Barbariga"}]
    const state = reactive ({
      id: null,
      idApp : null ,
      cityName : "" ,
      name : "" ,
      address : "" ,
      latitude : "" ,
      longitude : "" ,
      timeCleaning : 45 ,
      priceCleaning : 0 ,
      costCleaning : 0 ,
      space : 0 ,
      priceGeneralCleaning : 0 ,
      costGeneralCleaning : 0 ,
      timeGeneralCleaning : 0 ,
      priceTouchUpCleaning : 0 ,
      costTouchUpCleaning : 0 ,
      timeTouchUpCleaning : 0 ,
      userId : null ,
      externalApartmentId : "" ,
      costOneLinen : 0 ,
      priceOneLinen : 0,
      ownerId : "",
      customerId : null ,
      customerName : "" ,
      contactName : "" ,
      customerOIB : "" ,
      customerAddress : "" ,
      contactEmail : "" ,
      contactTel : "",
      addExternalCustomerForm :"display: none;",
// officeId:"",
      config : {
        enableTime: true,
        mode: "range",
        dateFormat: "d.m.Y H:i",
        time24hr: true,
        minTime: "8:00",
        maxTime: "22:00",
        wrap: true, // set wrap to true only when using 'input-group'
        defaultDate: [],
        // altFormat: 'd.m.Y',
        // altInput: false,
        // locale: "ru-RU", // locale for this instance only
      },
      owner: null,
      topicId: "",
      scheduleId: "",
      schedules: [],
      dateRange: "",
    })
    const owners = computed(() => store.getters.owners);
    const lastAddedOwner = reactive(store.getters.lastAddedOwner);
    const lastAddedOwnerId = computed(() => (!lastAddedOwner)?null:lastAddedOwner.id);
    const addExternalCustomer = () => {

          if (state.addExternalCustomerForm === "display: none;")
            state.addExternalCustomerForm = "display: block;"
          else {
            state.addExternalCustomerForm = "display: none;"
          }
        }
    const searchAddressOnMap = () => {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?address="+ state.address + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      axios.get(url).then((response) => {
        if(response.status===200) {
          state.latitude = response.data.results[0].geometry.location.lat;
          state.longitude = response.data.results[0].geometry.location.lng;
          state.address = response.data.results[0].formatted_address;
        }
      });
    };
    const addNewObject = async () => {
      const {data} = await ticketService.newObject({
        // id: state.idApp,
        city_name: state.cityName,
        address: state.address,
        user_id: Number(localStorage.getItem("USER_ID")),
        name: state.name,
        external_apartment_id: state.externalApartmentId,
        latitude: Number(state.latitude),
        longitude: Number(state.longitude),
        price_general_cleaning: Number(state.priceGeneralCleaning),
        cost_general_cleaning: Number(state.costGeneralCleaning),
        time_general_cleaning: Number(state.timeGeneralCleaning),
        price_touch_up_cleaning: Number(state.priceTouchUpCleaning),
        cost_touch_up_cleaning: Number(state.costTouchUpCleaning),
        time_touch_up_cleaning: Number(state.timeTouchUpCleaning),
        time_cleaning: Number(state.timeCleaning),
        price_cleaning: Number(state.priceCleaning),
        cost_cleaning: Number(state.costCleaning),
        cost_one_linen: Number(state.costOneLinen),
        price_one_linen: Number(state.priceOneLinen),
        space: Number(state.space),
        owner_id: state.ownerId
      }).then((response) => {

        if(response.status===201) {
          console.log(response.data)
          // const state = response.data.
          // store.dispatch(ActionTypes.getObject, response.data.apartment)
           store.commit(MutationType.addObject, response.data)
           state.apartmentId = response.data.id
           store.commit(MutationType.changeModal, false);
        }
        else {
          //report errors
          console.log(response)
        }
        router.push('/apartments');
      });
    }
    const updateApartment =  () => {
      debugger
      store.dispatch(ActionTypes.updateObject, {
        id: state.id,
        city_name: state.cityName,
        office_id: state.officeId,
        dateRange: state.dateRange,
        scheduleIsNeed: "Y",
        address: state.address,
        user_id: Number(localStorage.getItem("USER_ID")),
        name: state.name,
        external_apartment_id: state.externalApartmentId,
        latitude: Number(state.latitude),
        longitude: Number(state.longitude),
        price_general_cleaning: Number(state.priceGeneralCleaning),
        cost_general_cleaning: Number(state.costGeneralCleaning),
        time_general_cleaning: Number(state.timeGeneralCleaning),
        price_touch_up_cleaning: Number(state.priceTouchUpCleaning),
        cost_touch_up_cleaning: Number(state.costTouchUpCleaning),
        time_touch_up_cleaning: Number(state.timeTouchUpCleaning),
        time_cleaning: Number(state.timeCleaning),
        price_cleaning: Number(state.priceCleaning),
        cost_cleaning: Number(state.costCleaning),
        cost_one_linen: Number(state.costOneLinen),
        price_one_linen: Number(state.priceOneLinen),
        space: Number(state.space),
        owner_id: state.ownerId
      });
      router.push('/apartments');
    }
    const addCustomer = async () => {

      // store.dispatch(ActionTypes.createOwner,{
        ownerService.createOwner({
        name: state.customerName,
        contact_name: state.contactName,
        contact_email: state.contactEmail,
        contact_tel: state.contactTel,
        OIB: state.customerOIB,
        address: state.customerAddress
      }).then((response) => {

          if(response.status===201) {
            console.log(response.data)
            // const state = response.data.
            // store.dispatch(ActionTypes.getObject, response.data.apartment)
            store.commit(MutationType.createOwner, response.data)
            state.ownerId = response.data.id
            state.addExternalCustomerForm = "display: none;"
          }
          else {
            //report errors
            console.log(response)
          }
        });
    }
    const setFields = () => {

      if (props.id !== null && props.id !== undefined ) {
        const apartment = store.getters.getApartmentById(props.id) //getApartmetById(Number(props.id))
        if (apartment) {
          state.id = apartment.id  ;// null;
          // state.idApp = apartment.id  ;// null;
          state.cityName = apartment.city_name  ;// "";
          state.address = apartment.address  ;// props.addressFromMap;
          state.userId = apartment.user_id  ;// localStorage.getItem("USER_ID");
          state.name = apartment.name  ;// "";
          state.externalApartmentId = apartment.external_apartment_id  ;// null;
          state.latitude = apartment.latitude  ;// props.latitude;
          state.longitude = apartment.longitude  ;// props.longitude;
          state.priceGeneralCleaning = apartment.price_general_cleaning  ;// 0;
          state.costGeneralCleaning = apartment.cost_general_cleaning  ;// 0;
          state.timeGeneralCleaning = apartment.time_general_cleaning  ;// 0;
          state.priceTouchUpCleaning = apartment.price_touch_up_cleaning  ;// 0;
          state.costTouchUpCleaning = apartment.cost_touch_up_cleaning  ;// 0;
          state.timeTouchUpCleaning = apartment.time_touch_up_cleaning  ;// 0;
          state.timeCleaning = apartment.time_cleaning  ;// 45;
          state.priceCleaning = apartment.price_cleaning  ;// 0;
          state.costCleaning = apartment.cost_cleaning  ;// 0;
          state.costOneLinen = apartment.cost_one_linen  ;// 0;
          state.priceOneLinen = apartment.price_one_linen  ;// 0;
          state.space = apartment.space ;// 0;
          state.ownerId = apartment.owner_id ;// null;
          state.officeId = apartment.office_id ;// null;
          state.customerId = apartment.owner_id  ;// null;
          state.customerName = apartment.owner_id? apartment.owner.customerName:"";// "";
          state.contactName = apartment.owner_id? apartment.owner.contactName:"";// "";
          state.customerOIB = apartment.owner_id? apartment.customerOIB :"";// "";
          state.customerAddress = apartment.owner_id? apartment.owner.customerAddress :"";// "";
          state.contactEmail = apartment.owner_id? apartment.owner.contactEmail :"";// "";
          state.contactTel = apartment.owner_id? apartment.owner.contactTel :"";// "";
          state.addExternalCustomerForm =  "display: none";
          if (apartment.owner) {
            state.owner = apartment.owner;
          }
          if (apartment.schedules.length > 0) {
            apartment.schedules.forEach((schedule)=>{
              state.schedules.push(schedule);
            });
           const schedule = state.schedules[state.schedules.length-1];
            state.config.defaultDate.push (new Date(schedule.from * 1000))
            state.config.defaultDate.push (new Date(schedule.to *1000))
            state.dateRange = schedule.range
            state.topicId = schedule.topic_id
          }
        }
      }
      else {
        // state.idApp = null;
        state.id = null;
        state.cityName = "";
        state.address = "Nova cesta, 119, Opatija";
        state.userId = localStorage.getItem("USER_ID");
        state.name = "New Object";
        state.externalApartmentId = null;
        state.latitude = 45.3148;
        state.longitude = 14.2873;
        state.priceGeneralCleaning = 0;
        state.costGeneralCleaning = 0;
        state.timeGeneralCleaning = 0;
        state.priceTouchUpCleaning = 0;
        state.costTouchUpCleaning = 0;
        state.timeTouchUpCleaning = 0;
        state.timeCleaning = 45;
        state.priceCleaning = 0;
        state.costCleaning = 0;
        state.costOneLinen = 0;
        state.priceOneLinen = 0;
        state.space = 0;
        state.ownerId = null;
        state.customerId = null;
        state.customerName = "";
        state.contactName = "";
        state.customerOIB = "";
        state.customerAddress = "";
        state.contactEmail = "";
        state.contactTel = "";
        state.addExternalCustomerForm = "display: none"
      }
    }
    onMounted(()=>{
      store.dispatch(ActionTypes.fetchOwners,null);
      store.dispatch(ActionTypes.fetchTopics);
      setFields()


      mapboxgl.accessToken = "pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw";

      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        // center: [(id === null || id === undefined)? props.lon:14.2873, (id === null || id === undefined)? props.lat:45.3148],
        // center: (props.lon === null || props.lon === undefined)?[14.2873, 45.3148]: [props.lon, props.lat],
        center: [state.longitude, state.latitude],
        zoom: 8
      });
      // {{ ((id === null || id === undefined)? 'New Apartment:  ' : 'Apartment :   ')}}

      map.on('load', () => {
        map.scrollZoom.enable();
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.ScaleControl({position: 'bottom-left'}));
        const marker = new mapboxgl.Marker({draggable: false})
            .setLngLat( [state.longitude, state.latitude])
            .setPopup(new mapboxgl.Popup({ offset: 25 })
                .setHTML('<div>{{state.name}} <br> {{state.address}}</div>'))
            .addTo(map);

      })
    })
    return {
      addNewObject,
      lastAddedOwnerId,
      searchAddressOnMap,
      addCustomer,
      addExternalCustomer,
      updateApartment,
      owners,
      offices,
      topics,
      ...toRefs(state),
      ...toRefs(lastAddedOwner),
    }
  },
  components: { Multiselect, flatPickr },
  props: {
    id: {
      type: Number,
    },
    title: {
      type: String,
    },
    lat: {
      type: Number,
    },
    lon: {
      type: Number,
    },
    addressFromMap: {
      type: String,
    },
  },


}
</script>

<style scoped>
#map {
  position: relative;
  /*left:25%;*/
  width: 100%;
  top: 0;
  bottom: 0;
  min-height: 270px;
  height: 270px;
}

.customer-detail {
  position: relative;
  padding: 28px 28px;
  border-radius: 10px;
  /*background: #01818c;*/
  background: whitesmoke;
  margin: 13px 13px 13px;
  /*color: whitesmoke;*/
  color: #777777;
  border: 3px solid #01818c;
}
</style>
